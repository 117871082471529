<template>
  <div class="card">
    <div class="card__header">
      <img :src="logo" :alt="title">
      <h3>{{title}}</h3>
    </div>
    <div class="card__list">
      <ul>
        <li v-for="(item, i) in items" :key="i">{{item}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValueCard',
  props: {
    logo: {
      type: String,
      required: true,
      default: () => (''),
    },
    title: {
      type: String,
      required: true,
      default: () => (''),
    },
    items: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: var(--background-color-light-purple);
  border-radius: 10px;
  border-bottom: 3px solid var(--border-highlight-color-default);
  margin: 10px 10px;
  padding: 20px;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 30px;
      margin-right: 10px;
    }
    h3 {
      color: var(--text-color-blue);
      margin: 0;
      font-size: 14px;
    }
  }
  &__list {
    ul {
      margin: 12px 0 0 8px;
      li {
        margin: 8px 0;
        list-style-image: url("/img/list-arrow.svg");
        color: var(--text-color-blue);
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}
</style>
