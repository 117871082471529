<template>
  <div class="card">
    <div class="card__header">
      <img :src="logo" :alt="title">
      <h3>{{title}}</h3>
    </div>
    <div class="card__list">
      <div class="card__list__item" v-for="(item, i) in items" :key="i">
        <div class="card__list__item__text">
          <img src="/img/check.svg" alt="">
          <span>{{item}}</span>
        </div>
        <div class="card__list__item__divider">
          <img src="/img/plans-items-divider.svg" alt="">
        </div>
      </div>
    </div>
    <div class="card__btn">
      <Button
        :classes="['btn--more']"
        @onclick="$emit('clicked')">
        {{action ? action : $i18n.t('common.select_plan')}}
      </Button>
    </div>
      <div class="card__notice">
        <small>{{notice}}</small>
      </div>
  </div>
</template>

<script>
export default {
  name: 'PlanCard',
  props: {
    logo: {
      type: String,
      required: true,
      default: () => (''),
    },
    title: {
      type: String,
      required: true,
      default: () => (''),
    },
    items: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    action: {
      type: String,
      required: false,
    },
    notice: {
      type: String,
      required: false
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background-color: var(--background-color-default);
  border-radius: 10px;
  border-bottom: 3px solid var(--border-highlight-color-default);
  margin: 10px 10px;
  padding: 20px;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 30px;
      margin-right: 10px;
    }
    h3 {
      color: var(--text-color-blue);
      margin: 0;
      font-size: 14px;
    }
  }
  &__list {
    margin: 30px 0 0 8px;
    height: inherit;
    margin-bottom: 120px;
      &__item {
        &__text {
          margin: 4px 0;
          color: var(--text-color-blue);
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 12px;
          img {
            width: 14px;
            margin: 0 10px 1px 0
          }
        }
        &__divider {
          display: block;
          margin: 0;
          max-width: 400px;
        }
        &:last-child {
          .card__list__item__divider {
            display: none;
          }
        }
      }
  }
  &__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 50px;
    button {
      display: block;
      margin: auto;
    }
  }
  &__notice {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 25px 15px 25px;
    color: var(--text-color-blue);
    font-size: 12px;
    text-align: center;
    line-height: 10px;
    }
}
</style>
