<template>
  <Page :classes="['page--static']">
    <BannerComponent/>
    <div class="index">
      <BlockSection>{{$t('info.index_title')}}</BlockSection>
      <div class="wrap">
        <div class="index__inner">
          <div class="index__inner__text" v-html="$t('info.index_text')"></div>
          <div class="index__inner__img">
            <img class="" src="/img/world.svg" alt="IndexODS">
          </div>
        </div>
      </div>
    </div>
    <div class="openods">
      <BlockSection>{{$t('info.openods_title')}}</BlockSection>
      <div class="wrap">
        <img class="openods__img" src="/img/pnud.svg" alt="OpenODS">
        <div class="openods__text" v-html="$t('info.openods_text')"></div>
        <img
          class="openods__table"
          :src="`/img/${$i18n.locale}/info/ods-table.svg`"
          alt="OpenODS Table"
          @error="$event.target.src = `/img/es/info/ods-table.svg`"
        >
      </div>
      <Button
        :classes="['btn--more']"
        @onclick="$router.push({name: 'sistema', params: {locale: $i18n.locale } })">
        {{$t('common.more')}}
      </Button>
    </div>
    <div class="entities">
      <BlockSection>{{$t('info.entities.title')}}</BlockSection>
      <div class="wrap">
        <div class="entities__inner">
          <div class="entities__inner__first">
            <img src="/img/local-entities.svg" alt="Local Entities">
            <h3>{{$t('info.entities.local')}}</h3>
            <img class="dots" src="/img/dots-gray.svg" alt="Gray Dots">
            <img src="/img/companies.svg" alt="Companies">
            <h3>{{$t('info.entities.companies')}}</h3>
          </div>
          <div class="entities__inner__middle">
            <img src="/img/entitytypes.svg" alt="Entity Types">
          </div>
          <div class="entities__inner__last">
            <img src="/img/third-sector.svg" alt="Third Sector">
            <h3>{{$t('info.entities.third-sector')}}</h3>
            <img class="dots" src="/img/dots-gray.svg" alt="Gray Dots">
            <img src="/img/education.svg" alt="Education">
            <h3>{{$t('info.entities.education')}}</h3>
          </div>
        </div>
      </div>
      <Button
        :classes="['btn--more']"
        @onclick="$router.push({name: 'aquienvadirigido', params: {locale: $i18n.locale } })">
        {{$t('common.more')}}
      </Button>
    </div>
    <div class="value">
      <BlockSection>{{$t('info.value.title')}}</BlockSection>
      <div class="wrap">
        <div class="value__text" v-html="$t('info.value.text')"></div>
        <div class="value__cards">
          <ValueCard
            class="value__cards__item"
            :logo="`/img/value-entity.svg`"
            :title="$t('info.value.entities')"
            :items="entityList"
          />
          <ValueCard
            class="value__cards__item"
            :logo="`/img/value-user.svg`"
            :title="$t('info.value.users')"
            :items="usersList"
          />
          <ValueCard
            class="value__cards__item"
            :logo="`/img/value-society.svg`"
            :title="$t('info.value.society')"
            :items="societyList"
          />
        </div>
      </div>
    </div>
    <div class="plans">
      <BlockSection>{{$t('info.plans.title')}}</BlockSection>
      <div class="wrap">
        <div class="plans__cards">
          <PlanCard
            class="plans__cards__item"
            :logo="`/img/plans-starter.svg`"
            :title="$t('info.plans.starter')"
            :items="starterList"
            :action="$t('info.plans.starter_action')"
            @clicked="$router.push({name: 'register', params: {locale: $i18n.locale } })"
          />
          <PlanCard
            class="plans__cards__item"
            :logo="`/img/plans-advanced.svg`"
            :title="$t('info.plans.advanced')"
            :items="advancedList"
            :action="$t('info.plans.advanced_action')"
            :notice="$t('info.plans.advanced_notice')"
            @clicked="$router.push({name: 'contact', params: {locale: $i18n.locale } })"
          />
          <PlanCard
            class="plans__cards__item"
            :logo="`/img/plans-partnership.svg`"
            :title="$t('info.plans.partnership')"
            :items="partnershipList"
            :action="$t('info.plans.partnership_action')"
            :notice="$t('info.plans.partnership_notice')"
            @clicked="$router.push({name: 'contact', params: {locale: $i18n.locale } })"
          />
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import BannerComponent from '@/components/banner/BannerComponent.vue';
import ValueCard from '@/components/layout/card/ValueCard.vue';
import PlanCard from '@/components/layout/card/PlanCard.vue';

export default {
  name: 'InfoPage',
  components: {
    BannerComponent,
    ValueCard,
    PlanCard,
  },
  data() {
    return {
      entityList: [
        this.$i18n.t('info.value.entity_list.reporting'),
        this.$i18n.t('info.value.entity_list.learning'),
        this.$i18n.t('info.value.entity_list.compliance'),
        this.$i18n.t('info.value.entity_list.positioning'),
        this.$i18n.t('info.value.entity_list.registry'),
      ],
      usersList: [
        this.$i18n.t('info.value.users_list.transparence'),
        this.$i18n.t('info.value.users_list.counts'),
        this.$i18n.t('info.value.users_list.accesibility'),
        this.$i18n.t('info.value.users_list.high_value'),
        this.$i18n.t('info.value.users_list.radiography'),
      ],
      societyList: [
        this.$i18n.t('info.value.society_list.acceleration'),
        this.$i18n.t('info.value.society_list.digital'),
        this.$i18n.t('info.value.society_list.development'),
        this.$i18n.t('info.value.society_list.alliace'),
        this.$i18n.t('info.value.society_list.conscious'),
      ],

      starterList: [
        this.$i18n.t('info.plans.starter_list.diagnosis'),
        this.$i18n.t('info.plans.starter_list.profile'),
        this.$i18n.t('info.plans.starter_list.reporting'),
        this.$i18n.t('info.plans.starter_list.access'),
      ],
      advancedList: [
        this.$i18n.t('info.plans.advanced_list.diagnosis'),
        this.$i18n.t('info.plans.advanced_list.profile'),
        this.$i18n.t('info.plans.advanced_list.reporting'),
        this.$i18n.t('info.plans.advanced_list.access'),
        this.$i18n.t('info.plans.advanced_list.verification'),
        this.$i18n.t('info.plans.advanced_list.assistance'),
        this.$i18n.t('info.plans.advanced_list.metrics'),
        this.$i18n.t('info.plans.advanced_list.ranking'),
      ],
      partnershipList: [
        this.$i18n.t('info.plans.partnership_list.platform'),
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding: 0;
}

.wrap {
  padding: 0 10px;
  max-width: var(--layout-max-width-narrow);
  display: block;
  margin: auto;
}

.dots {
  padding: 10px 0;
}

.index {
  padding-bottom: 50px;
  &__inner {
    display: flex;
    flex-direction: row;
    &__text {
      flex: 1;
      color: var(--primary-color-default);
      text-align: start;
      font-size: 18px;
      font-family: var(--font-family-info);
      font-weight: normal;
      font-style: normal;
    }
    &__img {
      flex: 1;
      img {
        float: right;
        width: 100%;
        max-width: 300px;
      }
    }
  }
}
.openods {
  background-color: var(--background-color-turquoise);
  padding-bottom: 50px;
  width: 100%;
  &__img {
    max-width: 200px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
  &__text {
    color: var(--text-color-gray);
    max-width: 400px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
  }
  &__table {
    margin: 20px auto;
  }
  button {
    display: block;
    margin: auto;
    margin-top: 20px;
  }
}
.entities {
  background-color: var(--background-color-gray);
  padding-bottom: 50px;
  &__inner {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    img {
      display: block;
      margin: auto;
      width: 50%;
    }
    h3 {
      margin-top: 5px;
      font-size: min(2vw, 14px);
      color: var(--text-color-light-blue);
    }
    &__first {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: stretch;
      text-align: center;
    }
    &__middle {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        display: block;
          margin: auto;
        width: 70%;
      }
    }
    &__last {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 20%;
    }
  }
  button {
    display: block;
    margin: auto;
    margin-top: 20px;
  }
}
.value {
  padding-bottom: 50px;
  &__text {
    color: var(--text-color-gray);
    max-width: 800px;
    display: block;
    margin: auto;
    margin-bottom: 40px;
    text-align: center;
  }
  &__cards {
    width: 100%;
    display: flex;
    &__item {
      flex: 1;
    }
  }
}
.plans {
  background-color: var(--background-color-turquoise);
  padding-bottom: 50px;
  &__cards {
    width: 100%;
    display: flex;
    &__item {
      flex: 1;
    }
  }
}

@media screen and (max-width: 779px) {
  .index {
    &__inner {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      &__img {
        margin-bottom: 20px;
      }
    }
  }
  .value {
    &__cards {
      flex-direction: column;
    }
  }
  .plans {
    &__cards {
      flex-direction: column;
    }
  }
}

@media screen and (min-width: 780px) {
  .page {
    padding: 0;
  }
}

@media screen and (min-width: 1200px) {
  .page {
    padding: 0;
    max-width: none;
    margin: 0;
  }
}
</style>
